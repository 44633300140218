<template>
    <div class="form_ct flex" style="margin-top: 20px;">
        <p class="upLoad_text">风格：</p>
        <div class="upload_box">
            <el-cascader v-model="style" :options="style_list" @change="handleChange"></el-cascader>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    created() { },
    mounted() { },
    props:{
        style_list:Array
    },
    data() {
        return {
           style:'',
           
        }
    },
    methods: {
        handleChange(value){
            console.log(value);
            this.$emit('getCascader',value[1])
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai';
::v-deep .el-cascader {
    width: 250px;
}
</style>